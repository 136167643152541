// 开发环境域名
// https://kpkd.yixiangonline.com
// https://pintai.kupaokading.com
const host_development = 'https://kpkd.yixiangonline.com'

export default {
// 版本
    version: '1.0.0',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW',
}
