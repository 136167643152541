import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
    path: '/finance',
    name: 'finance',
    meta: { title: '财务' },
    redirect: '/finance/index',
    component: Main,
    children: [
        {
            path: '/finance/index',
            name: 'finance_index',
            meta: {
                title: '财务汇总',
                parentPath: '/finance',
                icon: 'tradingdata',
                permissions: ['view']
            },
            component: () => import('@/views/finance/index.vue')
        },
        {
            path: '/finance/shop',
            name: 'finance_lists',
            meta: {
                title: '财务报表',
                parentPath: '/finance',
                icon: 'icon_user_gaikuang'
            },
            component: Blank,
            redirect: '/finance/shop',
            children: [
                {
                    path: '/finance/shop',
                    name: 'finance_shop',
                    meta: {
                        title: '门店报表',
                        parentPath: '/finance',
                        permissions: ['view']
                    },
                    component: () => import('@/views/finance/shop.vue')
                },
                {
                    path: '/finance/partner',
                    name: 'finance_partner',
                    meta: {
                        title: '合作商报表',
                        parentPath: '/finance'
                    },
                    component: () => import('@/views/finance/partner.vue')
                }
            ]
        },
    ]
}]

export default routes
