import Main from '@/layout/main.vue'
const routes = [{
    path: '/user',
    name: 'user',
    meta: { title: '会员' },
    redirect: '/user',
    component: Main,
    children: [
        {
            path: '/user',
            name: 'config',
            meta: {
                title: '会员管理',
                icon: 'icon_user',
                parentPath: '/user',
                permissions: ['view']
            },
            component: () => import('@/views/user/index.vue')
        },
    ]
}]

export default routes
