
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/setting',
  name: 'setting',
  meta: { title: '设置' },
  redirect: '/setting/platform/config',
  component: Main,
  children: [
    {
      path: '/setting/platform/config',
      name: 'platform',
      meta: {
        title: '平台设置',
        parentPath: '/setting',
        icon: 'icon_set_weihu'
      },
      component: Blank,
      redirect: '/setting/platform/config',
      children: [{
        path: '/setting/platform/config',
        name: 'config',
        meta: {
          title: '平台信息',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/platform/config.vue')
      }, {
        path: '/setting/platform/keep_on_record',
        name: 'keep_on_record',
        meta: {
          title: '备案信息',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/platform/keep_on_record.vue')
      }, {
        path: '/setting/platform/about',
        name: 'about',
        meta: {
          title: '关于我们',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/platform/about.vue')
      }]
    }, {
      path: '/setting/service/service',
      name: 'config',
      meta: {
        title: '联系客服',
        icon: 'icon_user_dengji',
        parentPath: '/setting',
        permissions: ['view']
      },
      component: () => import('@/views/setting/service/service.vue')
    }, {
      path: '/setting/storage/index',
      name: 'setting_storage_index',
      meta: {
        title: '存储设置',
        icon: 'icon_set_jiaoyi',
        parentPath: '/setting',
        permissions: ['view']

      },
      component: () => import('@/views/setting/storage/index.vue')
    },  {
      path: '/setting/storage/storage',
      name: 'setting_storage_storage',
      meta: {
        title: '存储设置',
        parentPath: '/setting',
        hidden: true,
        prevPath: '/setting/storage/index',
        permissions: ['view']

      },
      component: () => import('@/views/setting/storage/storage.vue')
    },{
      path: '/setting/security/account',
      name: 'journal',
      meta: {
        title: '修改密码',
        icon: 'icon_fenxiao_set',
        parentPath: '/setting',
        permissions: ['view']
      },
      component: () => import('@/views/setting/security/account.vue')
    },
    
			{
				path: '/setting/pay_config',
				name: 'pay_config',
				meta: {
					title: '支付配置',
					parentPath: '/setting',
					icon: 'icon_set_pay',
					permissions: ['view']
				},
				component: () => import('@/views/setting/pay_config.vue')
			},
    {
      path: '/setting/tencent_map/tencent_map',
      name: 'config',
      meta: {
        title: '腾讯地图',
        icon: 'icon_dianpu_fengge',
        parentPath: '/setting',
        permissions: ['view']
      },
      component: () => import('@/views/setting/tencent_map/index.vue')
    }, 
    // 员工设置
    {
      path: '/setting/permissions',
      name: 'admin',
      meta: {
        title: '员工设置',
        parentPath: '/setting',
        icon: 'icon_set_user'
      },
      component: Blank,
      redirect: '/setting/permissions/admin',
      children: [
        {
          path: '/setting/permissions/admin',
          name: 'permissions_admin',
          meta: {
            title: '员工',
            parentPath: '/setting',
            permissions: ['view']
          },
          component: () => import('@/views/setting/permissions/admin.vue')
        },
        {
          path: '/setting/permissions/admin_edit',
          name: 'permissions_admin_edit',
          meta: {
            hidden: true,
            title: '管理员',
            parentPath: '/setting',
            prevPath: '/setting/permissions/admin'
          },
          component: () => import('@/views/setting/permissions/admin_edit.vue')
        },
        {
          path: '/setting/permissions/role',
          name: 'permissions_role',
          meta: {
            title: '角色',
            parentPath: '/setting',
            permissions: ['view']
          },
          component: () => import('@/views/setting/permissions/role.vue')
        },
        {
          path: '/setting/permissions/role_edit',
          name: 'permissions_role_edit',
          meta: {
            hidden: true,
            title: '编辑角色',
            parentPath: '/setting',
            prevPath: '/setting/permissions/role'
          },
          component: () => import('@/views/setting/permissions/role_edit.vue')
        }
      ]
    }, 
     // 消息通知
    {
      path: "/setting/sms",
      component: Blank,
      meta: { title: "消息通知", permissions: ["view"], icon: 'icon_yingxiao_qipao', parentPath: '/setting' },
      children: [
        {
          path: "/setting/notification/notification",
          component: () => import("@/views/setting/notification/index.vue"),
          meta: {  title: "通知设置", permissions: ["view"], parentPath: '/setting' }
        },
        {
          path: "/setting/notification/detail",
          component: () => import("@/views/setting/notification/detail.vue"),
          meta: { hidden: true, parentPath: '/setting', prevPath: '/setting/notification/notification', title: "通知设置", permissions: ["view"] }
        },
        {
          path: "/setting/sms/index",
          component: () => import("@/views/setting/sms/index.vue"),
          meta: { title: "短信设置", permissions: ["view"], parentPath: '/setting' }
        },
        {
          path: "/setting/sms/detail",
          component: () => import("@/views/setting/sms/detail.vue"),
          meta: { hidden: true, parentPath: '/setting', prevPath: '/setting/sms/index',title: "短信设置", permissions: ["view"] }
        }
      ]
    },
    {
      path: '/setting/system_maintain/journal',
      name: 'system_maintain',
      meta: {
        title: '系统维护',
        parentPath: '/setting',
        icon: 'icon_qudao'
      },
      component: Blank,
      redirect: '/setting/system_maintain/journal',
      children: [{
        path: '/setting/system_maintain/journal',
        name: 'journal',
        meta: {
          title: '系统日志',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/system_maintain/journal.vue')
      }, {
        path: '/setting/system_maintain/cache',
        name: 'cache',
        meta: {
          title: '系统缓存',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/system_maintain/cache.vue')
      },
      //  {
      //   path: '/setting/system_maintain/updata',
      //   name: 'updata',
      //   meta: {
      //     title: '系统升级',
      //     // hidden: true,
      //     parentPath: '/setting',
      //     permissions: ['view']
      //   },
      //   component: () => import('@/views/setting/system_maintain/updata.vue')
      // },
      {
        path: '/setting/system_maintain/environment',
        name: 'environment',
        meta: {
          title: '系统环境',
          // hidden: true,
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/system_maintain/environment.vue')
      }, {
        path: '/setting/system_maintain/task',
        name: 'task',
        meta: {
          title: '定时任务',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/system_maintain/task/lists.vue')
      }, {
        path: '/setting/system_maintain/task_edit',
        name: 'task_edit',
        meta: {
          hidden: true,
          title: '定时任务',
          parentPath: '/setting',
          permissions: ['view']
        },
        component: () => import('@/views/setting/system_maintain/task/edit.vue')
      }]
    }
  ]
}]

export default routes
