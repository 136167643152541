import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    // 文章资讯
    {
        path: '/article',
        name: 'article',
        meta: {
            title: '资讯',
            moduleName: 'article'
        },
        redirect: '/article/lists',
        component: Main,
        children: [
            {
                path: '/article/lists',
                name: 'article',
                meta: {
                    title: '资讯管理',
                    parentPath: '/article',
                    moduleName: 'article',
                    icon: 'icon_notice',
                    permissions: ['view']
                },
                component: () => import('@/views/article/lists.vue')
            },
            {
                path: '/article/article_edit',
                name: 'article_edit',
                meta: {
                    hidden: true,
                    title: '资讯管理',
                    parentPath: '/article',
                    prevPath: '/article/lists',
                    moduleName: 'article'
                },
                component: () =>
                    import('@/views/article/article_edit.vue')
            },
            {
                path: '/article/category_lists',
                name: 'category_lists',
                meta: {
                    title: '资讯分类',
                    parentPath: '/article',
                    moduleName: 'article',
                    icon: 'icon_notice',
                    permissions: ['view']
                },
                component: () =>
                    import('@/views/article/category_lists.vue')
            }
        ]
    }
]

export default routes
