import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
    path: '/partner',
    name: 'partner',
    meta: { title: '合作商' },
    redirect: '/partner/lists',
    component: Main,
    children: [
        {
            path: '/partner/lists',
            name: 'partner_lists',
            meta: {
                title: '合作商列表',
                parentPath: '/partner',
                icon: 'yiguanzhugongyingshang',
                permissions: ['view']
            },
            component: () => import('@/views/partner/partner/lists.vue')
        },
        {
            path: '/partner/lists_detail',
            name: 'lists_detail',
            meta: {
                hidden: true,
                title: '合作商信息',
                parentPath: '/partner',
                prevPath: '/partner/lists',
                icon: 'yiguanzhugongyingshang',
                permissions: ['view']
            },
            component: () => import('@/views/partner/partner/lists_edit.vue')
        },
        {
            path: '/partner/modify_account',
            name: 'modify_account',
            meta: {
                hidden: true,
                title: '修改合作商账号',
                parentPath: '/partner',
                prevPath: '/partner/lists',
                icon: 'icon-shuju-yingshoutongji',
                permissions: ['view']
            },
            component: () => import('@/views/partner/partner/modify_account.vue')
        },
        {
            path: '/partner/charge',
            name: 'charge',
            meta: {
                title: '君币明细',
                parentPath: '/partner',
                icon: 'icon_set_pay',
                permissions: ['view']
            },
            component: () => import('@/views/partner/charge/lists.vue')
        },
        {
            path: '/partner/charge_edit',
            name: 'charge_edit',
            meta: {
                hidden: true,
                title: '合作商充值',
                parentPath: '/partner',
                prevPath: '/partner/charge',
                permissions: ['view']
            },
            component: () => import('@/views/partner/charge/edit.vue')
        }
    ]
}]

export default routes
