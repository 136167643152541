import { ActionTree, Module, MutationTree } from "vuex";
import { UserState, RootState } from "../type";
import { GetterTree } from "vuex";

// State
const state: UserState = {
    userInfos: {},
}


// Getters
const getters: GetterTree<UserState, RootState> = {
    
    // 获取Token
    tokens: state => state.userInfos?.token || null,
}


// Mutations
const mutations: MutationTree<UserState> = {
    // 设置用户信息
    setUserInfos: (state, payload): void => {
        state.userInfos = payload;
    },

    // 清除用户信息
    clearUserInfo: (state): void => {
        state.userInfos = {};
    }
}


// Actions
const actions: ActionTree<UserState, RootState> = {


}


const user: Module<UserState, RootState> = {
    state,
    mutations,
    actions,
    getters
}

export default user
