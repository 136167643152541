
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Perm extends Vue {
    get hasPermission() {
        const {
            permissions: { root, auth }
        } = this
        const { path, meta } = this.$route
        if (root) {
            // 为管理员
            return true
        }
        // 没有权限
        if (auth && auth.length == 0) {
            return false
        }
        this.$nextTick(() => {
            console.log(auth, path, root, this.permissions)
        })
        console.log(this)
        try {
            const actions = auth[path]
            if (!meta?.permissions) {
                // 不需要权限的页面
                return true
            }
            if (!actions) {
                // 需要权限但后台权限表路径对不上
                return false
            }

            return actions.some((item: string) => {
                return meta?.permissions.includes(item)
            })
        } catch (error) {
            console.log(error)
        }
    }
    get permissions() {
        return this.$store.getters.permissions
    }
}
