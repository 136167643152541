import router from './router'
import store from './store'
import NProgress from 'nprogress'


const loginPath = '/account/login'
const defaultPath = '/index'
const whiteList = ['login']
NProgress.configure({ showSpinner: false }) 
router.beforeEach((to, from, next) => {
    NProgress.start()
    // 设置文档title
    to.meta?.title && (document.title = to.meta.title)

    const token = store.getters.tokens;

    if (token) {
        if (to.path === loginPath) {
            next({ path: defaultPath })
        } else {
            next();
        }
    } else {
        if (whiteList.includes(to.name as string)) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next({ path: loginPath, query: { redirect: to.fullPath } })
        }
    }

})


router.afterEach(() => {
    NProgress.done()
})
