import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/shop',
  name: 'shop',
  meta: { title: '门店' },
  redirect: '/shop/lists',
  component: Main,
  children: [
    {
      path: '/shop/lists',
      name: 'shop_lists',
      meta: {
        title: '门店列表',
        parentPath: '/shop',
        icon: 'icon_pcshop',
        permissions: ['view']
      },
      component: () => import('@/views/shop/shop/lists.vue')
    },
    {
      path: '/shop/lists_detail',
      name: 'lists_detail',
      meta: {
        hidden: true,
        title: '门店信息',
        parentPath: '/shop',
        prevPath: '/shop/lists',
        icon: 'icon_dianpu_home',
        permissions: ['view']
      },
      component: () => import('@/views/shop/shop/lists_edit.vue')
    },
    {
      path: '/shop/modify_account',
      name: 'modify_account',
      meta: {
        hidden: true,
        title: '修改超级管理员账号',
        parentPath: '/shop',
        prevPath: '/shop/lists',
        icon: 'icon_dianpu_home',
        permissions: ['view']
      },
      component: () => import('@/views/shop/shop/modify_account.vue')
    },
    {
      path: '/shop/shop_type',
      name: 'shop_shop_type',
      meta: {
        title: '门店类型',
        parentPath: '/shop',
        icon: 'icon_yingxiaowf',
        permissions: ['view']
      },
      component: () => import('@/views/shop/shop_type/index.vue')
    },
    {
      path: '/shop/set_meal',
      name: 'set_meal',
      meta: {
        title: '套餐列表',
        parentPath: '/shop',
        icon: 'icon_qiandao_jilu',
        permissions: ['view']
      },
      component: () => import('@/views/shop/set_meal/lists.vue')
    },
    {
      path: '/shop/set_meal_edit',
      name: 'set_meal_edit',
      meta: {
        hidden: true,
        title: '门店套餐',
        parentPath: '/shop',
        prevPath: '/shop/set_meal',
        permissions: ['view']
      },
      component: () => import('@/views/shop/set_meal/edit.vue')
    },
    {
      path: '/shop/map',
      name: 'set_meal',
      meta: {
        title: '门店地图',
        parentPath: '/shop',
        icon: 'icon_dianpu_fengge',
        permissions: ['view']
      },
      component: () => import('@/views/shop/shop_map/index.vue')
    },
  ]
}]

export default routes
